@tailwind base;
@tailwind components;
@tailwind utilities;

.bg {
	/* background-size: cover; */
	animation: changeBg 35s infinite normal;
	-webkit-animation: changeBg 35s infinite normal;
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("../public/img_1.png");
	background-size: cover;
	background-position: center;
}

@keyframes changeBg {
	0%,
	100% {
		background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
			url("../public/img_1.png");
		background-size: cover;
        background-position: center;
	}
    
	14.28% {
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("../public/img_2.png");
        background-position: center;
        background-size: cover;
	}

	28.56%{
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("../public/img_3.jpeg");
        background-position: center;
        background-size: cover;
	}

	42.84%{
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("../public/img_4.jpeg");
        background-position: center;
        background-size: cover;
	}

	57.12%{
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("../public/img_5.jpeg");
        background-position: center;
        background-size: cover;
	}

	71.4%{
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("../public/img_6.jpeg");
        background-position: center;
        background-size: cover;
	}

	85.68%{
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("../public/img_7.jpeg");
        background-position: center;
        background-size: cover;}
}
